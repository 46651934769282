.LoginLink {
  text-decoration: none;
}

.LoginButton {
  background-color: #9146ff;
  color: #fff;
  height: 36px;
  padding: 6px 12px;
  text-transform: none;
}

.LoginButton:hover {
  background-color: #a66bff;
}

.LoginButton .LoginButtonImage {
  fill: #fff;
  height: 24px;
  margin-right: 10px;
}

.LoginButton .LoginButtonDivider {
  color: #bb90ff;
}

@media screen and (max-width: 600px) {
  .LoginButton .DisplayName {
    display: none;
  }
}
