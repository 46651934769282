.PlayerInfo {
  align-items: center;
  display: flex;
}

.PlayerImage {
  background-color: #eee;
  border-radius: 50%;
  height: 24px;
  margin-right: 6px;
}

tr .Matches {
  background-color: #eee;
}

tr .AverageTime {
  background-color: #ddd;
}

.ChampionshipEligible {
  background-color: #cfc;
}

.ChampionshipEligible .Matches {
  background-color: #bfb;
}

.ChampionshipEligible .AverageTime {
  background-color: #afa;
}
