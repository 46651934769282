.AdminLink {
  text-decoration: none;
}

.AdminButton {
  background-color: #900;
  color: #fff;
  height: 36px;
  margin-right: 20px;
  min-width: inherit;
  padding: 6px 12px;
  text-transform: none;
}

.AdminButton:hover {
  background-color: #c00;
}

.AdminIcon {
  height: 16px;
  margin-right: 2px;
}

@media screen and (max-width: 600px) {
  .AdminButton {
    padding: 6px;
  }

  .AdminText {
    display: none;
  }

  .AdminIcon {
    margin-right: 0;
  }
}
