.Grid {
  column-gap: 24px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  margin-top: 12px;
}

.Round::before {
  background-color: #fff;
  content: attr(data-name);
  font-size: 75%;
  left: 5px;
  padding: 0 5px;
  position: absolute;
  top: -10px;
}

.Round {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  position: relative;
}

.Input {
  width: 100%;
}

.Input:not(:last-child),
.Round:not(:last-child) {
  margin-bottom: 12px;
}

.LevelInputs {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(2, 1fr);
}

.Progress.isHidden {
  visibility: hidden;
}
