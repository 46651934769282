.Title,
.Header {
  align-items: center;
  display: flex;
}

.BracketButton {
  margin-left: 20px;
}

.NavigationIcons {
  font-size: 48px;
}

.Title .Link {
  color: inherit;
  height: 48px;
}

@media screen and (max-width: 600px) {
  .Header {
    justify-content: space-between;
  }

  .Title .TitleText {
    font-size: 1.8rem;
  }

  .NavigationIcons {
    font-size: 32px;
  }

  .Title .Link {
    height: 32px;
  }

  .BracketButton {
    border-radius: 50%;
    min-width: initial;
    padding: 5px;
  }

  .BracketButton {
    :global {
      .MuiButton-startIcon {
        margin: 0;
      }
    }
  }

  .BracketButtonText {
    display: none;
  }
}
