.Bracket {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  overflow-x: auto;
}

.Round {
  max-width: 250px;
  min-width: 250px;
}

.RoundContainer:first-child .Round,
.RoundContainer:first-child .RoundTitle {
  margin-left: 20px;
}

.RoundContainer:not(:first-child) .Round,
.RoundContainer:not(:first-child) .RoundTitle {
  margin-left: 60px;
}

.RoundTitle {
  align-items: center;
  background-color: #555;
  border-radius: 4px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6px;

  h6 {
    line-height: 100%;
  }
}

.Match {
  margin: 5px 0;
  overflow: visible !important;
  position: relative;
}

.MatchNumber {
  font-size: 11px;
  font-weight: 500;
  left: -20px;
  line-height: 11px;
  position: absolute;
  text-align: center;
  top: calc(50% - 6px);
  width: 20px;
}

.BracketContainer .Grid {
  display: grid;
  grid-template-columns: 24px 1fr min-content;
  height: 50px;
}

.Match .Button {
  border-radius: 4px;
}

.RoundContainer:not(:last-child) .Match:nth-child(odd)::after {
  border: 2px solid #ddd;
  border-left: 0;
  content: " ";
  height: calc(100% + 3px);
  position: absolute;
  right: -21px;
  top: calc(50% - 1px);
  width: 19px;
}

.RoundContainer:not(:first-child) .Match::before {
  border-top: 2px solid #ddd;
  content: " ";
  height: 1px;
  position: absolute;
  left: -40px;
  top: calc(50% - 1px);
  width: 20px;
}

.RoundContainer:nth-child(2) .Match {
  margin-top: 33px;
}

.RoundContainer:nth-child(2) .Match:not(:last-child) {
  margin-bottom: 60px;
}

.RoundContainer:nth-child(2) .Match::after {
  height: calc(100% + 58px) !important;
}

.RoundContainer:nth-child(3) .Match {
  margin-top: 88px;
}

.RoundContainer:nth-child(3) .Match:not(:last-child) {
  margin-bottom: 172px;
}

.RoundContainer:nth-child(3) .Match::after {
  height: calc(100% + 170px) !important;
}

.RoundContainer:nth-child(4) .Match {
  margin-top: 204px;
}

.BracketContainer .Player {
  display: flex;
  flex-direction: row;
}

.Seed,
.GamerTag,
.Wins {
  align-items: center;
  display: flex;
  height: 100%;
}

.GamerTag {
  padding-left: 7px;
}

.Seed {
  background-color: #555;
  color: #fff;
  font-size: 11px;
  font-weight: 500 !important;
  justify-content: flex-end;
  padding-right: 5px;
}

.Seed.Winner {
  background-color: #a83;
}

.GamerTag.Winner {
  color: #a83;
  font-weight: 500;
}

.GamerTag.Winner,
.Wins.Winner {
  background-color: #eee;
}

.Seed:first-child {
  border-top-left-radius: 4px;
}

.Seed:not(:first-child) {
  border-bottom-left-radius: 4px;
}

.Wins {
  justify-content: flex-end;
  padding-left: 5px;
  padding-right: 5px;
}

.Wins:not(:last-child) {
  border-top-right-radius: 4px;
}

.Wins:last-child {
  border-bottom-right-radius: 4px;
}

.ImageWrapper {
  line-height: 0;
}

.WinnerOf {
  font-style: italic;
}

.Bye,
.WinnerOf {
  color: #666;
}

.Link {
  color: inherit;
  text-decoration: none;
}

.Link:hover,
.Link:focus {
  text-decoration: underline;
}
