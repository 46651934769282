.Content {
  display: flex;
  flex-direction: column;
}

.Games {
  margin-top: 24px;
}

.Game {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 12px;
  position: relative;
}

.Game::before {
  background-color: #fff;
  content: attr(data-name);
  font-size: 75%;
  left: 5px;
  padding: 0 5px;
  position: absolute;
  top: -10px;
}

.Game .Player {
  column-gap: 12px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.Game .DeleteButton {
  position: absolute;
  right: 0;
  top: 0;
  display: none;
}

.Game:hover .DeleteButton {
  display: initial;
}

.Input {
  width: 100%;
}

.Input:not(:last-child),
.Game:not(:last-child) {
  margin-bottom: 12px;
}

.AddGameButton {
  align-self: flex-end;
  margin-top: 12px;
}

.Progress.isHidden {
  visibility: hidden;
}
