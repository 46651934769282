.Header {
  background-color: #333;
}

.MenuButton {
  color: white;
}

.DrawerList {
  width: 250px;
}

.DrawerList .Divider {
  margin: 8px 0;
}

.TitleLink {
  color: white;
  flex-grow: 1;
  text-decoration: none;
}

.Title {
  align-items: center;
  display: flex;
}

.TitleImage {
  height: 50px;
  margin-bottom: 5px;
  margin-right: 10px;
  margin-top: 5px;
}

@media screen and (max-width: 600px) {
  .TitleLink .TitleText {
    display: none;
  }
}
