.Content {
  display: flex;
  flex-direction: column;
}

.Input {
  width: 100%;
}

.Input:not(:last-child) {
  margin-bottom: 12px;
}

.Progress.isHidden {
  visibility: hidden;
}
