.RecordsContainer {
  max-width: 600px;
}

.PlayerInfo {
  align-items: center;
  display: flex;
}

.PlayerImage {
  background-color: #eee;
  border-radius: 50%;
  height: 24px;
  margin-right: 6px;
}
