.TwitchChip {
  background-color: #9146ff;
  color: #fff;
  font-weight: 500;
}

.TwitchChip:hover,
.TwitchChip:focus {
  background-color: #a66bff;
}

.TwitchIcon {
  fill: #fff;
  margin-left: 12px;
  margin-right: -4px;
  width: 16px;
}
