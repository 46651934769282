.Title {
  align-items: center;
  display: flex;

  > * {
    margin-right: 8px;
  }
}

.ProfileImage {
  border-radius: 50%;
  height: 48px;
}

.SectionTitle {
  margin: 12px 0;
}

.Table {
  max-width: max-content;
  width: 100%;
}

.Matches {
  display: flex;

  .Match {
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &.IsWinner {
      border-color: #0f0;

      .RoundInfo {
        background-color: #393;
      }
    }

    &.IsLoser {
      border-color: #f00;

      .RoundInfo {
        background-color: #933;
      }
    }

    .RoundInfo,
    .MatchInfo {
      padding: 8px;
      text-align: center;
    }

    .RoundInfo {
      background-color: #666;
      color: #fff;

      .RoundName {
        font-weight: 500;
      }

      .OpponentLink {
        color: #fff;
      }
    }
  }
}
